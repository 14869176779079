import { Suspense } from "react";
import { env } from "./constant/env";
import { Migration } from "./Pages";
import { Loader } from "./Components/UI";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Migration />,
  },
]);

const mainnet = {
  chainId: Number(env.chainId),
  name: env.chainName,
  currency: env.chainCurrency,
  explorerUrl: env.explorerUrl,
  rpcUrl: env.rpcUrl,
};

const metadata = {
  name: env.metaDataName,
  description: env.metaDataDescription,
  url: env.metaDataWebsiteUrl,
  icons: [env.metaDataIconWebsiteUrl],
};

const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: Number(env.defaultChainId), // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
// const modal = createWeb3Modal({
//   ethersConfig,
//   chains: [mainnet],
//   projectId: env.walletConnectProjectId,
//   enableAnalytics: true, // Optional - defaults to your Cloud configuration,
//   allWallets: 'HIDE',
// });
// const connector = [...modal.getConnectors()]
const modal: any = createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId: env.walletConnectProjectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  allWallets: "HIDE",
});
console.log("modalmodalmodal  :::::::::  before", modal);

// debugger
// Get the current connectors, filter out 'Trust Wallet', and set the filtered connectors back to the modal
modal.setConnectors(
  [...modal.getConnectors()].filter(
    (wallet: any) => wallet?.name !== "Trust Wallet"
  )
);
console.log("modalmodalmodal  :::::::::  after", [...modal.getConnectors()]);

// console.log(connector,"-------------------");
// debugger
function App() {
  return (
    <Suspense
      fallback={
        <>
          <Loader />
        </>
      }
    >
      <RouterProvider router={router} />
      <Toaster />
    </Suspense>
  );
}

export default App;
