import { ReactNode } from "react";
import "./CustomButton.scss";

const CustomButton = ({
  text,
  onClick,
  className,
  disabled,
  icon,
  type
}: {
  text?: string;
  onClick?: any;
  className?: string;
  disabled?: boolean;
  type: "reset" | "submit" | "button" | undefined;
  icon?: ReactNode;
}) => {
  return (
    <button
      disabled={disabled}
      className={`custom-button ${className}`}
      onClick={onClick}
      type={type}
    >
      {text}
      {icon ? <span className="btn-icon">{icon}</span> : ""}
    </button>
  );
};

export default CustomButton;
