import Cookies from 'js-cookie';

// Set a cookie
export const setCookie = (name: string, value: any, options: any = {}) => {
    Cookies.set(name, value, options);
};

// Get a cookie
export const getCookie = (name: string) => {
    return Cookies.get(name);
};

// Remove a cookie
export const removeCookie = (name: string, value: any, options: any = {}) => {
    Cookies.remove(name, options);
};
