import { ethers } from 'ethers';

/**
 * Check if the private key corresponds to a valid Ethereum address
 * @param key 
 * @returns 
 */
export const isValidkey = (key: string) => {
    try {
        const wallet = new ethers.Wallet(key);

        if (wallet?.address) {
            return wallet.address;
        } else {
            return null;
        }
    } catch (error) {
        // If an error occurs during private key validation, it's invalid
        return null;
    }
}

export const isEnvLoaded = (key: string) => {
    if (!process.env[key]) {
        throw new Error('Environment variables are missing.');
    } else {
        console.log('ENV OK');
    }
}


export const noExponent = function (num: number | string) {
	try {
		const data = String(num).split(/[eE]/);
		if (data.length === 1) return data[0];
		let z = '';
		const sign = Number(num) < 0 ? '-' : '';
		const str = data[0].replace('.', '');
		let mag = Number(data[1]) + 1;
		if (mag < 0) {
			z = `${sign}0.`;
			while (mag++) z += '0';
			return z + str.replace(/^\-/, '');
		}
		mag -= str.length;
		while (mag--) z += '0';
		return str + z;
	} catch (error) {
		console.log('Exponent error', error);
		return '0';
	}
};