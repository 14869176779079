class Env {
  rpcUrl: string = "";
  chainId: string = "";
  chainName: string = "";
  backendUrl: string = "";
  hexChainId: string = "";
  explorerUrl: string = "";
  metaDataName: string = "";
  chainCurrency: string = "";
  defaultChainId: string = "";
  contractAddress: string = "";
  blackHoleAddress: string = "";
  metaDataWebsiteUrl: string = "";
  metaDataDescription: string = "";
  metaMaskInbrowserUrl: string = "";
  walletConnectProjectId: string = "";
  metaDataIconWebsiteUrl: string = "";
  dexUrl: string = "";
  walletUrl: string = "";
  ethScan: string = "";
  static instance: Env | null = null;

  constructor() {
    this.checkAndLoadEnv();
  }

  static getInstance() {
    if (!Env.instance) {
      Env.instance = new Env();
    }
    return Env.instance;
  }

  checkAndLoadEnv() {
    try {
      this.rpcUrl =
        process.env.REACT_APP_ETHEREUM_RPC_URL ?? "https://cloudflare-eth.com";
      this.chainId = process.env.REACT_APP_CHAIN_ID ?? "1";
      this.chainName = process.env.REACT_APP_CHAIN_NAME ?? "Ethereum";
      this.explorerUrl =
        process.env.REACT_APP_EXPLORER_URL ?? "https://scan.qa.5ire.network/";
      this.metaDataName = process.env.REACT_APP_METADATA_NAME ?? "";
      this.backendUrl =
        process.env.REACT_APP_BACKEND_URL ??
        "https://api.migration.5ire.network";
      this.hexChainId = process.env.REACT_APP_HEX_CHAIN_ID ?? "";
      this.chainCurrency = process.env.REACT_APP_CHAIN_CURRENCY ?? "ETH";
      this.defaultChainId = process.env.REACT_APP_DEFAULT_CHAIN_ID ?? "1";
      this.contractAddress =
        process.env.REACT_APP_CONTRACT_ADDRESS ??
        "0xda12447e330004f3e02d5ea44cc1305697c14634";
      this.blackHoleAddress = process.env.REACT_APP_BLACK_HOLE_ADDRESS ?? "";
      this.metaDataWebsiteUrl =
        process.env.REACT_APP_METADATA_WEBSITE_URL ?? "";
      this.metaDataDescription =
        process.env.REACT_APP_METADATA_DESCRIPTION ?? "";
      this.metaDataIconWebsiteUrl =
        process.env.REACT_APP_METADATA_ICON_WEBSITE_URL ?? "";
      this.walletConnectProjectId =
        process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ??
        "eab10dac28480484762c806346c3d8b2";
      this.metaMaskInbrowserUrl =
        process.env.REACT_APP_METAMASK_INBROWSER_URL ?? "";
      this.dexUrl =
        process.env.REACT_APP_NOMINATOR_URL ??
        "https://nominator.5ire.network/";
      this.walletUrl =
        process.env.REACT_APP_WALLET_URL ??
        "https://chromewebstore.google.com/detail/5ire-wallet/keenhcnmdmjjhincpilijphpiohdppno";
      this.ethScan =
        process.env.REACT_APP_ETH_SCAN ?? "https://sepolia.etherscan.io/tx";
    } catch (error) {
      console.error("error while load envs : ", error);
    }
  }
}

export const env = Env.getInstance();
