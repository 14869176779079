import { env } from "./env";

export const STORAGE_KEYS = {
    IS_LOGGEDIN: "isLoggedIn"
}

export const ERROR_MESSAGES = {
    INPUT_REQUIRED: "This field is required",
    INCORRECT_KEY: "Invalid private key",
}


export const COOKIE_KEY = {
    TC_ACCEPTED: 'T&CAccept'
}

export const LINKS = {
    DEX: env.dexUrl,
    EXPLORER: env.explorerUrl,
    WALLET: env.walletUrl
}


export const CONST = {
    WALLET: 'wallet',
    DEX: 'dex',
    EXPLORER: 'explorer',
    COIN: '5ire',
    PLATFORM_NAME: '5ire',
    DECIMALS: 9
}

export const CHAINS = {
    ETH:'ethereum',
    BSC:'sepolia'
}

export const CHAIN_ID = {
    'ethereum' : '1',
    'sepolia':'11155111'
}