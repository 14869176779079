import "./Header.scss";
import axios from "axios";
import toast from "react-hot-toast";
import { env } from "../../../constant/env";
import logo from "../../../Assets/Images/logo.png";
import { AppContext } from "../../../Store/context";
import { useState, useEffect, useContext } from "react";
import { useDisconnect, useWeb3Modal } from "@web3modal/ethers/react";
import CustomButton from "../CustomButton/CustomButton";
import ToggleIcon from "../../../Assets/Images/ToggleIcon.svg";
import { Button, Form, Modal, Offcanvas } from "react-bootstrap";

function Header() {

  const keys = {
    name: 'name',
    mailId: 'mailId',
    txHash: 'txHash',
    attachment: 'attachments',
    description: "description",
  }

  interface IQuery {
    name: string;
    txHash: string,
    mailId: string,
    attachment: any,
    description: string,
  }

  const [query, setQuery] = useState<IQuery>({
    name: '',
    mailId: '',
    txHash: '',
    attachment: '',
    description: '',
  });

  const [error, setError] = useState<IQuery>({
    name: '',
    txHash: '',
    mailId: '',
    attachment: '',
    description: '',
  });
  const { disconnect } = useDisconnect();
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const {open} = useWeb3Modal();
  
  const {
    isLoggedIn,
    tcAccepted,
    isMetamask,
    setShowTc,
    setLoggedIn,
    setIsMetamask,
    setShowWalletPopup
  } = useContext(AppContext);

  const handleShowHelp = () => setShow(true);
  const handleShow = () => setShowDrawer(true);
  const handleClose = () => setShowDrawer(false);

  const handleCloseHelp = () => {
    setShow(false)
    clearError();
    clearInputs();
  };

  useEffect(() => {
    // query.txHash && query.description && query.mailId && !error.txHash && !error.description && !error.mailId && !error.attachment
    if (
      !error.name &&
      !error.mailId &&
      !error.txHash &&
      !error.attachment &&
      !error.description &&
      query.mailId &&
      query.txHash &&
      query.description
    ) {
      setDisable(false)
    } else {
      setDisable(true);
    }
  }, [error.attachment, error.description, error.mailId, error.txHash, query.description, query.description, query.mailId, error.name]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const clearError = () => {
    setError({
      name: '',
      txHash: '',
      mailId: '',
      attachment: '',
      description: '',
    });
  }

  const clearInputs = () => {
    setQuery({
      name: '',
      mailId: '',
      txHash: '',
      attachment: '',
      description: '',
    });
  }

  function validateEmail(email: string) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  }

  function validateTxHash(txHash: string) {
    if (txHash.startsWith('0x') && txHash.length === 66) {
      return true;
    }
    return false;
  }

  function validateName(name: string) {
    const nameRegex = /^[a-zA-Z]+(?:[\s-'][a-zA-Z]+)*$/;
    return nameRegex.test(name);
  }

  function handleChange(e: any) {
    const name = e.target.name;
    let value = e.target.value;

    switch (name) {

      case keys.name:
        value = value.trim()
        setError({
          ...error,
          name: ""
        });
        if (value && !validateName(value)) {
          setError({
            ...error,
            name: "Invalid name"
          });
        }
        setQuery((p: IQuery) => ({
          ...p,
          name: value,
        }));
        break;


      case keys.mailId:
        value = value.trim()
        const emailRegex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-])*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

      if (!value) {
        setError({
          ...error,
          mailId: 'Please enter mail id'
        });
      } else if (!emailRegex.test(value)) {
        setError({
          ...error,
          mailId: 'Please enter a valid email address'
        });
      } else {
        setError({
          ...error,
          mailId: ''
        });
      }

      setQuery((p: IQuery) => ({
        ...p,
        mailId: value,
      }));
      break;

      case keys.txHash:
        if (!value) {
          setError({
            ...error,
            txHash: 'Please enter transaction hash'
          });
          break;
        }
        if (!validateTxHash(value)) {
          setError({
            ...error,
            txHash: 'Invalid transaction hash'
          });
        } else {
          setQuery((p: IQuery) => ({
            ...p,
            txHash: value,
          }));
          setError({
            ...error,
            txHash: ''
          });
        }
        break;

      case keys.description:
        if (!value) {
          setError({
            ...error,
            description: 'Please enter description'
          });
          break;
        }
        setQuery((p: IQuery) => ({
          ...p,
          description: value,
        }));
        setError({
          ...error,
          description: ''
        });
        break;

      case keys.attachment:

        const files = e.target.files;

        if (files.length > 5) {
          setQuery({
            ...query,
            attachment: '',
          });
          setError({
            ...error,
            attachment: 'You can only select up to 5 files.',
          });
        } else {
          setQuery({
            ...query,
            attachment: files,
          });
          setError({
            ...error,
            attachment: '',
          });
        }
        break;

      default:
        setQuery((p: IQuery) => ({
          ...p,
          [name]: value,
        }));
        break;
    }
  }

  
  async function handleQuerySubmit() {
    try {

      const newErrors: {
        txHash?: string,
        description?: string,
        mailId?: string
      } = {};

      if (!disable) {
        const formDataWithFile = new FormData();
        formDataWithFile.append(keys.name, query.name);
        formDataWithFile.append(keys.txHash, query.txHash);
        formDataWithFile.append(keys.mailId, query.mailId);
        formDataWithFile.append(keys.description, query.description);

        for (let i = 0; i < query?.attachment?.length; i++) {
          formDataWithFile.append('attachments', query?.attachment[i]);
        }

        handleCloseHelp();

        await axios.post(env.backendUrl + '/send-email', formDataWithFile, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        toast.success('Thank you for reaching out! Your query has been successfully submitted');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Failed to submit data.');
    }
  }

  function handleDisconnect() {
    if (isMetamask) {
      setIsMetamask(false);
    }
    else {
      disconnect();
    }
    setLoggedIn(false);
  }

  async function handleConnect() {
    try {
      handleClose();
      if (!tcAccepted) {
        setShowTc(true);
      }
      else {
        setShowWalletPopup(true);
        // await open();

        // setCurrentStep(1);
      }
    } catch (error) {
      console.log('error while connecting: ', error);
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 1000,
    cssEase: "linear",
  };


  const connectWalletHandler = () =>{
    try {
      open()
    } catch (error) {
      console.log("error", error)
      
    }
  
  };
  return (
    <>
      <div className={`header ${scrolling ? "scrollHeader" : ""}`}>
        {/* 
        <Slider {...settings}>
          <div className="slider">
            <h3>
              Phase 1 of 5ire token migration is complete, next phase will start soon.
            </h3>
          </div>
          <div className="slider">
            <h3>f
              Phase 1 of 5ire token migration is complete, next phase will start soon.
            </h3>
          </div>
          <div className="slider">
            <h3>
              Phase 1 of 5ire token migration is complete, next phase will start soon.
            </h3>
          </div>
        </Slider> 
        */}
        <div className="header__inner container">
          <img src={logo} alt="logo" />
          <div className="header__inner_right">
            <p>
              <span onClick={handleShowHelp} style={{ cursor: 'pointer' }}>  Need help? </span>
            </p>
            {isLoggedIn ?
              <CustomButton onClick={handleDisconnect} type="button" text="disconnect wallet" className="headerBtn" disabled={false} />
              :
              <CustomButton 
              
              onClick={connectWalletHandler} 
              
              type="button" text="connect wallet" className="headerBtn" disabled={false} />
            }
          </div>
          <div className="toggleIcon">
            <button
              onClick={handleShow}
              style={{ border: "none", background: "none" }}
            >
              <img src={ToggleIcon} alt="Toggle Drawer" />
            </button>
          </div>
        </div>
      </div>
      <Offcanvas show={showDrawer} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton  >
          <img src={logo} alt="logo" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            <span onClick={handleShowHelp} style={{ cursor: 'pointer' }}> Need help? </span>
          </p>
          {isLoggedIn ?
            <CustomButton onClick={handleDisconnect} type="button" text="disconnect wallet" className="headerBtn" disabled={false} />
            :
            <CustomButton onClick={handleConnect} type="button" text="connect wallet" className="headerBtn" disabled={false} />
          }
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show} onHide={handleCloseHelp} className="contactUs">
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="innerForm">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name (optional)</Form.Label>
              <Form.Control
                type="text"
                name={keys.name}
                autoComplete="off"
                placeholder="Enter name"
                onChange={handleChange}
                autoFocus
              />
              <span className="inputError">{error.name}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mail Id (required) </Form.Label>
              <Form.Control
                type="email"
                name={keys.mailId}
                value={query.mailId}
                autoComplete="off"
                placeholder="Mail Id"
                onChange={handleChange}
                autoFocus
                required
              />
              <span className="inputError">{error.mailId}</span>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Transaction Hash (required)</Form.Label>
              <Form.Control
                type="text"
                name={keys.txHash}
                autoComplete="off"
                placeholder="Transaction Hash"
                onChange={handleChange}
                autoFocus
                required
              // className="inputError"                
              />
              <span className="inputError">{error.txHash}</span>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Attachment (optional) </Form.Label>
              <Form.Control
                type="file"
                placeholder="Attachment"
                onChange={handleChange}
                name={keys.attachment}
                // value={query.attachment}
                autoFocus
                multiple
              />
              <span className="inputError" >{error.attachment}</span>
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description (required)</Form.Label>
              <Form.Control
                rows={1}
                required
                as="textarea"
                autoComplete="off"
                onChange={handleChange}
                name={keys.description}
                style={{fontSize:'1.5rem'}}
              />
              <span className="inputError" style={{fontSize:'1.5rem'}} >{error.description}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0" }}>
          <Button variant="primary" onClick={handleQuerySubmit} style={{ fontSize: '16px', padding: "10px", borderRadius: "10px" }} className={disable ? "disable" : ""}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;
