import Web3 from "web3";
import {
  useWeb3ModalError,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { env } from "../constant/env";
import { CHAINS, CHAIN_ID, CONST, COOKIE_KEY } from "../constant";
import { ERC20_ABI } from "../constant/abi";
import { Contract } from "web3-eth-contract";
import { noExponent } from "../Utility/common";
import { getCookie } from "../Utility/cookies";
import { useState, createContext, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";

type ContextValueType = {
  txDetails: {
    txHash: string;
    value: string;
  };
  setTxDetails: React.Dispatch<
    React.SetStateAction<{ txHash: string; value: string }>
  >;
  tokenBalance: string;
  setTokenBalance: React.Dispatch<React.SetStateAction<string>>;
  claimedCoinCount: string;
  setClaimedCoinsCount: React.Dispatch<React.SetStateAction<string>>;
  accAddress: string;
  setAccountAddress: React.Dispatch<React.SetStateAction<string>>;
  web3Provider: Web3 | null;
  setProvider: React.Dispatch<React.SetStateAction<Web3 | null>>;
  contractInstance: any | null;
  setContractInstance: React.Dispatch<React.SetStateAction<any | null>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isLoggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tcAccepted: boolean;
  setTcAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  showTc: boolean;
  setShowTc: React.Dispatch<React.SetStateAction<boolean>>;
  walletPopup: boolean;
  setShowWalletPopup: React.Dispatch<React.SetStateAction<boolean>>;
  isMetamask: boolean;
  setIsMetamask: React.Dispatch<React.SetStateAction<boolean>>;
  // isDisconnected: boolean;
  // setDisconnected: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<ContextValueType>({
  txDetails: {
    txHash: "",
    value: "",
  },
  setTxDetails: () => {},
  tokenBalance: "",
  setTokenBalance: () => {},
  accAddress: "",
  setAccountAddress: () => {},
  claimedCoinCount: "",
  setClaimedCoinsCount: () => {},
  web3Provider: null,
  setProvider: () => {},
  setContractInstance: () => {},
  contractInstance: null,
  currentStep: 0,
  setCurrentStep: () => {},
  isLoggedIn: false,
  setLoggedIn: () => {},
  loading: false,
  setLoading: () => {},
  tcAccepted: false,
  setTcAccepted: () => {},
  showTc: false,
  setShowTc: () => {},
  walletPopup: false,
  setShowWalletPopup: () => {},
  isMetamask: false,
  setIsMetamask: () => {},
});

function Context(data: { children: any }) {
  const { error } = useWeb3ModalError();
  // const { walletProvider } = useWeb3ModalProvider();
  const [currentStep, setCurrentStep] = useState(0);
  // const { address, isConnected } = useWeb3ModalAccount();

  const [showTc, setShowTc] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [metamaskProvider, setMetamaskProvider] = useState<Web3 | null>(null);
  const [walletPopup, setShowWalletPopup] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [tokenBalance, setTokenBalance] = useState<string>("");
  const [tokenDecimals, setTokenDecimals] = useState<string>("");
  const [claimedCoinCount, setClaimedCoinsCount] = useState<string>("");
  const [web3Provider, setProvider] = useState<Web3 | null>(null);
  // const [contractInstance, setContractInstance] = useState<any | null>(null);
  const [tokenContract, setTokenContract] = useState<Contract<any> | null>(
    null
  );
  const [tcAccepted, setTcAccepted] = useState(
    getCookie(COOKIE_KEY.TC_ACCEPTED) ? true : false
  );
  const [txDetails, setTxDetails] = useState<{ txHash: string; value: string }>(
    {
      txHash: "",
      value: "",
    }
  );
  const [accAddress, setAccountAddress] = useState("");

  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [contractInstance, setContractInstance] = useState<any | null>(null);
  const [contractDecimal, setContractDecimal] = useState(0);
  const [selectedChain, setChain] = useState("");

  useEffect(() => {
    if (!isLoggedIn) {
      setCurrentStep(0);
      setProvider(null);
      setTokenBalance("");
      setTokenDecimals("");
      setLoggedIn(false);
      setContractInstance(null);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (
      address &&
      isConnected &&
      walletProvider &&
      (Number(chainId) === Number(Object.values(CHAIN_ID)[0]) ||
        Number(chainId) === Number(Object.values(CHAIN_ID)[1]))
    ) {
      (async () => {
        const provider = new Web3(walletProvider);
        setAccountAddress(address);
        let contractInstance: any;
        contractInstance = new provider.eth.Contract(
          ERC20_ABI,
          env.contractAddress
        );
        // }
        setContractInstance(contractInstance);
        setLoggedIn(true);
        setProvider(provider);
        setCurrentStep(1);
        setIsMetamask(false);
      })();
    } else {
      setAccountAddress("");
      setContractInstance(null);
    }
  }, [address, isConnected, walletProvider, selectedChain, chainId]);

  useEffect(() => {
    if (error) {
      let err = JSON.parse(JSON.stringify(error));
      console.log("error  :: ", err);
      if (err.code === 4001) {
        toast.error(err?.message);
      }
    }
  }, [error]);

  useEffect(() => {
    setTcAccepted(getCookie(COOKIE_KEY.TC_ACCEPTED) ? true : false);
    const getData = async () => {
      try {
        // const fetchRes = await fetch(
        //   "https://api.migration.5ire.network/claimed-coins"
        // );
        console.log(env, "ENV");
        const fetchRes = await fetch(`${env.backendUrl}/claimed-coins`);

        if (fetchRes.ok) {
          const res = await fetchRes.json();
          setClaimedCoinsCount(noExponent(res?.count ?? ""));
        } else {
          setClaimedCoinsCount("");
        }
      } catch (error) {
        // console.log('error while fetching api  : ', error);
      }
    };
    getData();
  }, []);

  const values = {
    showTc,
    loading,
    txDetails,
    tcAccepted,
    accAddress,
    isMetamask,
    isLoggedIn,
    walletPopup,
    currentStep,
    web3Provider,
    tokenBalance,
    tokenDecimals,
    tokenContract,
    // isDisconnected,
    contractInstance,
    claimedCoinCount,

    setShowTc,
    setLoading,
    setLoggedIn,
    setProvider,
    setTxDetails,
    setIsMetamask,
    setTcAccepted,
    setCurrentStep,
    setTokenBalance,
    // setDisconnected,
    setTokenDecimals,
    setTokenContract,
    setAccountAddress,
    setShowWalletPopup,
    setMetamaskProvider,
    setContractInstance,
    setClaimedCoinsCount,
  };
  return (
    <AppContext.Provider value={values}>{data.children}</AppContext.Provider>
  );
}

export default Context;
